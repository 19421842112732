// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
	@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
	@import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
	background: none transparent !important;
	margin: 0 !important;
	padding-top: 0.5rem;
	code {
		overflow-y: auto;
		display: block;

		span {
			background: none transparent !important;
		}
	}
}

.json > pre {
	background: none #fff !important;
}

.example-code > .json {
	background: none #fff !important;
}

.symbol.symbol-45 > svg {
	width: 100%;
	max-width: 45px;
	height: 45px;
}

.react-bootstrap-table {
	overflow-x: auto;
}

.react-bootstrap-table {
	th {
		outline: none;

		&.sortable {
			.svg-icon-sort {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;

				.svg-icon-sort {
					opacity: 1;
				}
			}
		}
	}

	.table.table-head-custom thead tr {
		.sortable-active {
			color: $primary !important;
		}
	}
}

.cursor-default {
	cursor: default !important;
}

.display-block {
	display: block;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: #b39743 !important;
}

.card-client-list {
	background-color: #f2f2f2;
	border-radius: 0.5em;
	padding: 0.75em 1em;
	margin-top: 1em;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	position: relative;
}

.margin-top-2 {
	margin-top: 2rem;
}
.margin-top-1 {
	margin-top: 1rem;
}
.margin-1 {
	margin: 1rem;
}

.scroll-component {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.icons-container {
	position: sticky;
	align-items: center;
	justify-content: center;
	display: flex;
	top: 15px;
	left: 50%;
	right: 50%;
	z-index: 600;
}

.icon-button {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 25px;
	border: 0px;
	height: 35px;
	margin-left: 20px;
	margin-right: 20px;
	width: 50px;
	z-index: 600;
}

.video-chat-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.video-container {
	position: sticky;
	top: 0px;
	z-index: 300;
	min-width: 0px;
	width: 100%;
	align-items: center;
	left: 50%;
	right: 50%;
}

.remote-video {
	position: absolute;
	top: 75px;
	max-width: 100%;
	height: auto;
	left: 50%;
	transform: translate(-50%, 0%);
}

.local-video {
	position: absolute;
	top: 90px;
	max-height: 120px;
	z-index: 300;
	right: 47%;
	transform: translate(-50%, 0%);
}

.retailer-container {
	position: relative;
	top: 0px;
	right: 0px;
	float: right;
	z-index: 300;
	min-width: 0px;
}

.white-button {
	background-color: white;
}

.black-button {
	background-color: black;
}

.red-button {
	background-color: red;
}
.red-button:hover {
	background-color: rgb(200, 2, 2);
}

.blue-button {
	background-color: #0067ff;
}

.grey-button {
	background-color: grey;
}
.grey-button:hover {
	background-color: rgb(80, 78, 78);
}
